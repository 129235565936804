<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
    style="height: 385px; min-width: 400px;"
  >
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          Admin Notice
        </b-card-title>
        <b-card-sub-title>관리자 공지사항</b-card-sub-title>
      </div>
    </b-card-header>

    <b-card-body>
      <b-table
        responsive
        striped
        show-empty
        hover
        small
        empty-text="No matching records found"
        :items="topNineNotices"
        :fields="fields1"
        style="text-align: center; font-size: small;"
        @row-clicked="goRoute('notice-admin-create')"
      >
        <template #cell(index)="data"
                  size="sm"
                  style="text-align: center; width: 1rem"
        >
          {{ index + 1 }}
        </template>
        <template #cell(category)="data">
          <div
            size="sm"
            style="text-align: center;"
          >
            {{ data.item.category }}
          </div>
        </template>
        <template #cell(title)="data">
          <div
            size="sm"
            style="text-align: left; min-width: 24px"
          >
            {{ truncateText(data.item.title, 14) }}
          </div>
        </template>
      </b-table>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BCardSubTitle, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'

import { createNamespacedHelpers } from 'vuex'

import { FETCH_SET_NOTICE_ADMIN } from '@/store/notice/action'

const noticeStore = createNamespacedHelpers('noticeStore')
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BCardSubTitle,
    BTable,
  },
  directives: {
    Ripple,
  },
  props: {
    siteSelected: String,
    data: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    siteSelected: {
      handler(event) {
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      isHovered: false,
      fields1: [
        { key: 'index', sortable: false, label: 'No.', thClass: 'col1' },
        { key: 'category', sortable: false, label: '카테고리', thClass: 'col2' },
        { key: 'title', sortable: false, label: '제목', thStyle: 'col3' },
      ],
    }
  },
  computed: {
    ...noticeStore.mapGetters({
      fetchSetNoticeAdmin: 'fetchSetNoticeAdmin',
    }),
    noticeStore() {
      return this.$store.state.noticeStore.fetchSetNoticeAdmin
    },
    topNineNotices() {
      return this.fetchSetNoticeAdmin.slice(0, 9);
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchSetNoticeAdmin: FETCH_SET_NOTICE_ADMIN,
    }),
    async fetchData() {
      const mySite = this.siteSelected
      await this.$fetchSetNoticeAdmin({
        site: mySite,
      })
      await this.dataProcess()
    },
    async dataProcess() {
      // 정렬된 데이터 가져오기
      const sortedData = this.fetchSetNoticeAdmin.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      // 최신 7개의 데이터 가져오기
      this.transactionData = sortedData.slice(0, 7).map(item => ({ mode: this.truncateMode(item.title) }))
    },
    // mode 문자열이 40바이트를 초과하는 경우에는 ...으로 표현
    truncateMode(mode) {
      // 최대 길이 설정
      const maxLength = 30
      // 문자열 길이가 최대 길이를 초과하는 경우
      if (mode.length > maxLength) {
        // 문자열을 최대 길이까지 자르고 ...을 추가하여 반환
        return `${mode.slice(0, maxLength)}...`
      }
      // 그 외의 경우에는 원래 문자열을 반환
      return mode
    },
    goRoute(list) {
      this.$router.push({ name: list }).catch(() => {})
    },
    truncateText(text, maxLength) {
      if (!text) return
      if (text.length <= maxLength) {
        return text
      }
      return `${text.substring(0, maxLength)}...`
    },
  },
}
</script>

<style scoped>
.transaction-item {
  transition: background-color 0.3s;
}

.transaction-item:hover {
  background-color: #252B3D; /* 호버 효과에 적용할 배경색상 */
}
</style>
